<template>
  <v-form ref="form">
    <v-row>
      <!-- RUT -->
      <v-col cols="12" md="6">
        <LabelComponent :text="docTypeDescription" />
        <v-chip
          label
          outlined
          large
          color="primary"
          style="width: 100%; height: 56px"
        >
          <v-icon left> mdi-format-horizontal-align-right </v-icon>
          {{ docNumber | rutFormat }}
        </v-chip>
      </v-col>

      <!-- RAZON SOCIAL -->
      <v-col cols="12" md="6" v-if="tipoPersona === 'JUR'">
        <LabelComponent text="Razón Social*" />
        <v-text-field
          placeholder="Nombre de la compañía"
          outlined
          v-model="form.razon_social"
          :rules="[rules.required]"
          name="editCompanySocialReazon"
        >
        </v-text-field>
      </v-col>

      <!-- NOMBRE -->
      <v-col cols="12" md="6">
        <LabelComponent text="Nombre*" />
        <v-text-field
          name="editUserName"
          v-model="form.nombre"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <!-- APELLIDO 1 -->
      <v-col cols="12" md="6">
        <LabelComponent text="Primer apellido*" />
        <v-text-field
          name="editUserLastName"
          v-model="form.primer_apellido"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <!-- APELLIDO 2 -->
      <v-col cols="12" md="6">
        <LabelComponent text="Segundo apellido*" />
        <v-text-field
          name="editUserSecondLastName"
          v-model="form.segundo_apellido"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <!-- FECHA -->
      <v-col cols="12" md="6">
        <LabelComponent text="Fecha de nacimiento*" />
        <v-text-field
          v-model="form.fecha_nacimiento"
          :rules="[rules.required, rules.date, rules.date18]"
          placeholder="dd/mm/yyyy"
          v-mask="dateMask"
          outlined
          name="editUserBirthDate"
        ></v-text-field>
      </v-col>

      <!-- GENERO -->
      <v-col cols="12" md="6">
        <LabelComponent text="Género*" />
        <v-select
          :items="genreList"
          v-model="form.genero"
          outlined
          name="editUserGender"
          :rules="[rules.required]"
        ></v-select>
      </v-col>

      <!-- TELEFONO -->
      <v-col cols="12" md="6">
        <LabelComponent text="Teléfono*" />
        <TextFieldPhone
          :text="form.telefono"
          @input="form.telefono = $event"
          :rules="[rules.required, rules.telefono]"
          name="editUserTelefono"
        />
      </v-col>

      <!-- EMAIL -->
      <v-col cols="12" md="6">
        <LabelComponent text="Correo electrónico*" />
        <v-text-field
          v-model="form.email"
          outlined
          name="editUserEmail"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-col>

      <!-- COMUNA -->
      <v-col cols="12" md="6">
        <LabelComponent text="Comuna*" />
        <v-autocomplete
          v-model="form.comuna"
          :items="comunaList"
          item-text="label"
          return-object
          :rules="[rules.required]"
          name="editUserComuna"
          outlined
          :loading="loadingComuna"
        >
        </v-autocomplete>
      </v-col>

      <!-- EXTRA -->
      <v-col cols="12" class="py-0">
        <v-slide-y-transition mode="out-in">
          <div v-if="showOther">
            <LabelComponent text="Información extra" />
            <v-textarea
              @click:append="showOther = false"
              name="otro"
              v-model="form.otro"
              outlined
              append-icon="mdi-close-circle-outline"
              @click:clear="showOther = false"
            ></v-textarea>
          </div>
          <v-checkbox
            v-else
            label="Información extra"
            class="mt-0"
            v-model="showOther"
            :true-value="true"
            :false-value="false"
          ></v-checkbox>
        </v-slide-y-transition>
      </v-col>
    </v-row>
    <slot name="policies-slot" />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import LabelComponent from "@/components/Inputs/Label.vue";
import {
  validarFecha,
  validarEmail,
  validarRutTipo,
  validarFecha18,
} from "@/store/resources/validate.js";
import { genreList } from "@/store/resources/constants.js";
import { dateMask } from "@/helpers/mask.js";

export default {
  name: "ClientChileForm",
  props: {
    docNumber: {
      type: [String, Number],
      default: "",
      required: true,
    },
    docType: {
      type: String,
      default: "",
    },
    docTypeDescription: {
      type: String,
      default: "",
    },
  },
  components: {
    TextFieldPhone,
    LabelComponent,
  },
  data: () => ({
    showOther: false,
    loadingComuna: false,
    comunaList: [],
    form: {
      razon_social: "",
      nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      fecha_nacimiento: "",
      genero: "",
      telefono: "",
      email: "",
      comuna: null,
      otro: "",
    },
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      date: (value) =>
        validarFecha(value) || "El formato de la fecha es incorrecto",
      date18: (value) =>
        validarFecha18(value) || "El cliente debe ser mayor de edad",
      telefono: (value) => value.length == 9 || "Teléfono inválido",
      email: (value) => validarEmail(value) || "Correo invalido.",
    },
  }),
  created() {
    this.setup();
  },
  computed: {
    genreList: () => genreList,
    dateMask: () => dateMask,
    tipoPersona() {
      return validarRutTipo(this.docNumber) ? "JUR" : "NAT";
    },
  },
  methods: {
    ...mapActions("Proposal", ["getLocations"]),

    setup() {
      this.setupComuna();
    },

    setFormData(data) {
      // Se establece la informacion previa del cliente
      this.form.nombre = data?.nombre ?? "";
      this.form.primer_apellido = data?.primer_apellido ?? "";
      this.form.segundo_apellido = data?.segundo_apellido ?? "";
      this.form.fecha_nacimiento =
        data?.fecha_nacimiento.split("/").join("") ?? "";
      this.form.genero = data?.genero ?? "";
      this.form.telefono = data?.datos_contacto?.telefono ?? "";
      this.form.email = data?.datos_contacto?.email || "";
      this.form.otro = data?.datos_contacto?.otro ?? "";
      // Establecer datos de la comuna
      this.form.comuna = data?.comuna
        ? {
            label: data.comuna_descripcion,
            value: data.comuna,
          }
        : null;
      // Establecer razon social
      if (this.tipoPersona == "JUR") {
        this.form.razon_social = data.razon_social;
      }
    },

    setupComuna() {
      this.loadingComuna = true;
      this.getLocations()
        .then((response) => {
          this.comunaList = response;
        })
        .finally(() => {
          this.loadingComuna = false;
        });
    },
  },
};
</script>
