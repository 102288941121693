<template>
  <div class="text-center">
    <v-dialog
      :value="value"
      width="40%"
      height="80%"
      attach="#main-container"
      @click:outside="close()"
    >
      <v-card class="py-4">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="h5 semiBold">¡Cliente registrado con éxito!</span>
            <v-btn icon color="error" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center mt-6 justify-center">
            <v-icon color="success" large>mdi-check</v-icon>
            <span class="p ml-6">
              El cliente con
              <span class="semiBold">{{ docTypeDescription }}</span
              >-
              <span class="semiBold" v-if="docType == 'RUT'">{{
                docNumber | rutFormat
              }}</span>
              <span v-else class="semiBold">{{ docNumber }}</span>
              se registró con éxito. A partir de ahora podrá cotizar productos.
            </span>
          </div>
          <div class="d-flex flex-column justify-center align-center my-6">
            <v-btn
              color="primary"
              class="text-none mt-4"
              width="214"
              height="41"
              rounded
              @click="goToClient()"
              >Ir a cotizar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "NewClientSuccessModal",
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    docNumber: {
      default: "",
      type: String,
    },
    docType: {
      default: "",
      type: String,
    },
    docTypeDescription: {
      default: "",
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    goToClient() {
      this.$emit("continue");
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
h4 {
  z-index: 10;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
}
#ComputerSvg {
  z-index: 10;
}
</style>
