<template>
  <v-dialog
    v-model="value"
    width="650"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn x-small fab text @click="$emit('close')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text v-html="policy"> </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="text-none" text @click="$emit('submit')">
          He leído y acepto
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PrivacyShowModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    policy: {
      type: String,
      default: "",
    },
  },
};
</script>
