<template>
  <v-form ref="form">
    <v-row>
      <!-- RUT -->
      <v-col cols="12" md="6">
        <LabelComponent :text="docTypeDescription" />
        <v-chip
          label
          outlined
          large
          color="primary"
          style="width: 100%; height: 56px"
        >
          <v-icon left> mdi-format-horizontal-align-right </v-icon>
          {{ docNumber }}
        </v-chip>
      </v-col>

      <!-- NOMBRE -->
      <v-col cols="12" md="6">
        <LabelComponent text="Nombre*" />
        <v-text-field
          name="editUserName"
          v-model="form.nombre"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <!-- APELLIDO 1 -->
      <v-col cols="12" md="6">
        <LabelComponent text="Primer apellido*" />
        <v-text-field
          name="editUserLastName"
          v-model="form.primer_apellido"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <!-- APELLIDO 2 -->
      <v-col cols="12" md="6">
        <LabelComponent text="Segundo apellido*" />
        <v-text-field
          name="editUserSecondLastName"
          v-model="form.segundo_apellido"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <!-- FECHA -->
      <v-col cols="12" md="6">
        <LabelComponent text="Fecha de nacimiento*" />
        <v-text-field
          v-model="form.fecha_nacimiento"
          :rules="[rules.required, rules.date, rules.date18]"
          placeholder="dd/mm/yyyy"
          v-mask="dateMask"
          outlined
          name="editUserBirthDate"
        ></v-text-field>
      </v-col>

      <!-- GENERO -->
      <v-col cols="12" md="6">
        <LabelComponent text="Género*" />
        <v-select
          :items="genreList"
          v-model="form.genero"
          outlined
          name="editUserGender"
          :rules="[rules.required]"
        ></v-select>
      </v-col>

      <!-- TELEFONO -->
      <v-col cols="12" md="6">
        <LabelComponent text="Teléfono*" />
        <TextFieldPhone
          :text="form.telefono"
          @input="form.telefono = $event"
          :rules="[rules.required, rules.telefono]"
          name="editUserTelefono"
        />
      </v-col>

      <!-- EMAIL -->
      <v-col cols="12" md="6">
        <LabelComponent text="Correo electrónico*" />
        <v-text-field
          v-model="form.email"
          outlined
          name="editUserEmail"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-col>

      <!-- DEPARTAMENTO -->
      <v-col cols="12">
        <LabelComponent text="Departamento" />
        <v-autocomplete
          v-model="form.region"
          :items="locations.regiones"
          @change="(form.provincia = null), (form.comuna = null)"
          return-object
          item-text="label"
          :rules="[rules.required]"
          outlined
        >
        </v-autocomplete>
      </v-col>

      <!-- PROVINCIA -->
      <v-col cols="12" md="6">
        <LabelComponent text="Provincia" />
        <v-autocomplete
          v-model="form.provincia"
          :items="
            locations.provincias.filter((e) => e.region == form.region?.value)
          "
          item-text="label"
          :disabled="!form.region"
          return-object
          @change="form.comuna = null"
          :rules="[rules.required]"
          outlined
        >
        </v-autocomplete>
      </v-col>

      <!-- COMUNA -->
      <v-col cols="12" md="6">
        <LabelComponent text="Distrito" />
        <v-autocomplete
          v-model="form.comuna"
          :items="
            locations.comunas.filter(
              (e) => e.provincia == form.provincia?.value
            )
          "
          item-text="label"
          return-object
          :disabled="!form.provincia"
          :rules="[rules.required]"
          outlined
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <slot name="policies-slot" />
  </v-form>
</template>

<script>
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import LabelComponent from "@/components/Inputs/Label.vue";
import {
  validarFecha,
  validarEmail,
  validarFecha18,
} from "@/store/resources/validate.js";
import { genreList } from "@/store/resources/constants.js";
import { dateMask } from "@/helpers/mask.js";

export default {
  name: "ClientPeruDNIForm",
  props: {
    docNumber: {
      type: [String, Number],
      default: "",
      required: true,
    },
    docType: {
      type: String,
      default: "",
    },
    docTypeDescription: {
      type: String,
      default: "",
    },
  },
  components: {
    TextFieldPhone,
    LabelComponent,
  },
  data: () => ({
    form: {
      nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      fecha_nacimiento: "",
      genero: "",
      telefono: "",
      email: "",
      region: null,
      provincia: null,
      comuna: null,
    },
    rules: {
      required: (value) => !!value || "Este campo es requerido.",
      date: (value) =>
        validarFecha(value) || "El formato de la fecha es incorrecto",
      date18: (value) =>
        validarFecha18(value) || "El cliente debe ser mayor de edad",
      telefono: (value) => value.length == 9 || "Teléfono inválido",
      email: (value) => validarEmail(value) || "Correo invalido.",
    },
  }),
  computed: {
    genreList: () => genreList,
    dateMask: () => dateMask,
    tipoPersona() {
      return "NAT";
    },
    locations() {
      const { regiones, provincias, comunas } = this.$store.getters.getLocation;
      return { regiones, provincias, comunas };
    },
  },
  methods: {
    setFormData(data) {
      // Se establece la informacion previa del cliente
      this.form.nombre = data?.nombre ?? "";
      this.form.primer_apellido = data?.primer_apellido ?? "";
      this.form.segundo_apellido = data?.segundo_apellido ?? "";
      this.form.fecha_nacimiento =
        data?.fecha_nacimiento.split("/").join("") ?? "";
      this.form.genero = data?.genero ?? "";
      this.form.telefono = data?.datos_contacto?.telefono ?? "";
      this.form.email = data?.datos_contacto?.email || "";
      // Establecer datos de territorio
      this.form.region =
        this.locations.regiones.find((e) => e.value === data?.region) ?? null;
      this.form.provincia =
        this.locations.provincias.find((e) => e.value === data?.provincia) ??
        null;
      this.form.comuna =
        this.locations.comunas.find((e) => e.value === data?.comuna) ?? null;
    },
  },
};
</script>
